<template>
  <component
    :is="tag"
    @click.native="hideSidebar"
    v-bind="$attrs"
    custom
    v-slot="{ isActive, isExactActive, href }"
  >
    <li
      :class="{
        'router-link-exact-active': isExactActive || $attrs.exact == false,
        'router-link-active': isActive,
      }"
    >
      <a class="nav-link" :href="href">
        <slot>
          <i v-if="link.icon" :class="link.icon"></i>
          <p>{{ link.name }}</p>
        </slot>
      </a>
    </li>
  </component>
</template>
  <script>
export default {
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: true,
    },
  },
  props: {
    link: {
      type: [String, Object],
      default: () => {
        return {
          name: "",
          path: "",
          icon: "",
        };
      },
    },
    tag: {
      type: String,
      default: "router-link",
    },
  },
  methods: {
    getOthers(val, val2, val3) {
      console.log("the others", val, val2, val3);
      return true;
    },
    hideSidebar() {
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
  <style>
</style>
  