import { sendTransaction } from "../transactionParser";

const initialState = () => ({
  groupHealth: null,

  //groups
  group: null,
  recipient: null,
  downloadData: null,
  csvtogroup: null,
  //ValidationEmail  
  email_validation: null,
  //generategroup
  generateGroup: null,
  //ambassador 
  ambassador: null,
  //language.php
  language: null,
  //socialProgram
  political_party: null,
  //socialProgram
  social_program: null,
  //title
  title: null,
  //quarter
  quarter: null,
  //province
  province: null,
  //supportStreng
  support_strength: null,
  //country
  country: null,
  //departement 
  department: null,
  //district
  district: null,
  //region
  region: null,

  get_timezone: null,

  //recipientgroup
  recipientgroup: null,

  ///group_filter
  group_filter: null,
  age_stats: null,
  age: 0,
  count: 0,

  start_email_validation: null,
  traffic_trend_detail: null,
  email_validation_status: null,
  stop_email_validation: null,
  app_columns_labels: null,
  user_columns: null,
  app_users: null,
  collection: null,
  collectionGroup: null,
  recipientGroupList: null,
  searchGroup: null,
  checkGroups: null,
});

const state = initialState();

export default {
  namespaced: true,
  // module assets
  state,
  getters: {
    
    stop_email_validation(state) {
      return state.stop_email_validation
    },
    email_validation_status(state) {
      return state.email_validation_status
    },
    get_timezone(state) {
      return state.get_timezone
    },
    csvtogroup(state) {
      return state.csvtogroup
    },
    app_users(state) {
      return state.app_users
    },
    app_columns_labels(state) {
      return state.app_columns_labels
    },
    user_columns(state) {
      return state.user_columns
    },

    traffic_trend_detail(state) {
      return state.traffic_trend_detail
    },

    start_email_validation(state) {
      return state.start_email_validation
    },
    recipientgroup(state) {
      return state.recipientgroup
    },
    downloadData(state) {
      return state.downloadData
    },
    groupHealth(state) {
      return state.groupHealth
    },
    //GroupTable
    group(state) {
      return state.group
    },
    //age stats 
    age_stats(state) {
      return state.age_stats
    },
    //recipients
    recipient(state) {
      return state.recipient
    },
    //ValidationEmail
    email_validation(state) {
      return state.email_validation
    },
    //generate group
    generateGroup(state) {
      return state.generateGroup
    },
    language(state) {
      return state.language
    },
    //title
    title(state) {
      return state.title
    },
    //quarter
    quarter(state) {
      return state.quarter
    },
    //province
    province(state) {
      return state.province
    },
    support_strength(state) {
      return state.support_strength
    },
    //political
    political_party(state) {
      return state.political_party
    },
    //country
    country(state) {
      return state.country
    },
    //department
    department(state) {
      return state.department
    },
    //district
    district(state) {
      return state.district
    },
    social_program(state) {
      return state.social_program
    },
    region(state) {
      return state.region
    },
    group_filter(state) {
      return state.group_filter
    },

    ambassador(state) {
      return state.ambassador
    },
    collection(state) {
      return state.collection
    },
    collectionGroup(state) {
      return state.collectionGroup
    },
    recipientGroupList(state) {
      return state.recipientGroupList
    },
    searchGroup(state) {
      return state.searchGroup
    },
    checkGroups(state) {
      return state.checkGroups
    },
   
  },
  actions: {
    callResetState({ commit }) {
      commit("setState");
    },
    callStop_email_validation({ commit }, payload) {
      sendTransaction(this, commit, "callStop_email_validation", "stop_email_validation", payload)
    },
    callGet_timezone({ commit }, payload) {
      sendTransaction(this, commit, "callGet_timezone", "get_timezone", payload)
    },
    callEmail_validation_status({ commit }, payload) {
      sendTransaction(this, commit, "callEmail_validation_status", "email_validation_status", payload)
    },
    callAmbassador({ commit }, payload) {
      sendTransaction(this, commit, "callAmbassador", "ambassador", payload)
    },
    callApp_users({ commit }, payload) {
      sendTransaction(this, commit, "callApp_users", "app_users", payload)
    },
    callCsvtogroup({ commit }, payload) {
      sendTransaction(this, commit, "callCsvtogroup", "csvtogroup", payload)
    },
    callAppser_columns({ commit }, payload) {
      sendTransaction(this, commit, "callAppser_columns", "app_columns_labels", payload)
    },
    callUser_columns({ commit }, payload) {
      sendTransaction(this, commit, "callUser_columns", "user_columns", payload)
    },
    callTrafficTrendDetail({ commit }, payload) {
      sendTransaction(this, commit, "callTrafficTrendDetail", "traffic_trend_detail", payload)
    },
    callStartEmailValidation({ commit }, payload) {
      sendTransaction(this, commit, "callStartEmailValidation", "start_email_validation", payload)
    },
    callRecipientgroup({ commit }, payload) {
      sendTransaction(this, commit, "callRecipientgroup", "recipientgroup", payload);
    },

    callGroupHealth({ commit }, payload) {
      sendTransaction(this, commit, "callGroupHealth", "widget_group_health", payload);
    },
    // age_stats
    callAge_stats({ commit }, payload) {
      sendTransaction(this, commit, "callAge_stats", "age_stats", payload);
    },
    //Group
    callGroup({ commit }, payload) {
      sendTransaction(this, commit, "callGroup", "group", payload);
    },
    callGenerateGroup({ commit }, payload) {
      sendTransaction(this, commit, "callGenerateGroup", "generate_group", payload);
    },
    //recipients
    callDownloadData({ commit }, payload) {
      sendTransaction(this, commit,
        "callDownloadData",
        payload.service,
        payload.payload);
    },
    callRecipient({ commit }, payload) {
      sendTransaction(this, commit,
        "callRecipient",
        "recipient",
        payload);
    },
    //emailValidation
    callEmail_validation({ commit }, payload) {
      sendTransaction(this, commit, "callEmail_validation", "email_validation", payload)
    },
    //language
    callLanguage({ commit }, payload) {
      sendTransaction(this, commit, "callLanguage", "language", payload)
    },
    //political party
    callPoliticalParty({ commit }, payload) {
      sendTransaction(this, commit, "callPoliticalParty", "political_party", payload)
    },
    //socialProgram 
    callSocialProgram({ commit }, payload) {
      sendTransaction(this, commit, "callSocialProgram", "social_program", payload)
    },
    //title
    callTitle({ commit }, payload) {
      sendTransaction(this, commit, "callTitle", "title", payload)
    },
    //quarter
    callQuarter({ commit }, payload) {
      sendTransaction(this, commit, "callQuarter", "quarter", payload)
    },
    //province
    callProvince({ commit }, payload) {
      sendTransaction(this, commit, "callProvince", "province", payload)
    },
    //support_strength
    callSupportStrenght({ commit }, payload) {
      sendTransaction(this, commit, "callSupportStrenght", "support_strength", payload)
    },
    //district
    callDistrict({ commit }, payload) {
      sendTransaction(this, commit, "callDistrict", "district", payload)
    },
    //country
    callCountry({ commit }, payload) {
      sendTransaction(this, commit, "callCountry", "country", payload)
    },
    //department
    callDepartment({ commit }, payload) {
      sendTransaction(this, commit, "callDepartment", "department", payload)
    },
    callRegion({ commit }, payload) {
      sendTransaction(this, commit, "callRegion", "region", payload)
    },
    //group_filter
    callGroupFilter({ commit }, payload) {
      sendTransaction(this, commit, "callGroupFilter", "group_filter", payload)
    },
    callCollection({ commit }, payload) {
      sendTransaction(this, commit, "callCollection", "collection", payload);
    },
    callCollectionGroup({ commit }, payload) {
      sendTransaction(this, commit, "callCollectionGroup", "group_collection", payload);
    },
    callRecipientGroupList({ commit }, payload) {
      sendTransaction(this, commit, "callRecipientGroupList", "recipient_group_list", payload);
    },
    callSearchGroup({ commit }, payload) {
      sendTransaction(this, commit, "callSearchGroup", "group", payload);
    },
    callCheckGroups({ commit }, payload) {
      sendTransaction(this, commit, "callCheckGroups", "group", payload);
    },
    //deletegroup andreceipients
    callDeleteGroup_Recipient({ commit }, payload) {
      sendTransaction(this, commit, "callDeleteGroup_Recipient", "delete_group_and_recipients", payload);
    }
  },
  mutations: {
    setState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setGet_timezone(state, payload) {
      state.get_timezone = payload
    },
    setStop_email_validation(state, payload) {
      state.stop_email_validation = payload
    },
    setEmail_validation_status(state, payload) {
      state.email_validation_status = payload
    },
    setAmbassador(state, payload) {
      state.ambassador = payload
    },
    setApp_users(state, payload) {
      state.app_users = payload
    },
    setDownloadData(state, payload) {
      state.downloadData = payload
    },
    setUserColumn(state, payload) {
      state.user_columns = payload
    },
    setApp_columns_labels(state, payload) {
      state.app_columns_labels = payload
    },
    setTrafficTrendDetails(state, payload) {
      state.traffic_trend_detail = payload
    },
    setRecipientgroup(state, payload) {
      state.recipientgroup = payload
    },
    setGroupHealth(state, payload) {
      state.groupHealth = payload
    },
    setCsvtogroup(state, payload) {
      state.csvtogroup = payload
    },
    setAge_stats(state, payload) {
      state.age_stats = payload
    },
    //group
    setGroup(state, payload) {
      state.group = payload
    },
    //recipient
    setRecipient(state, payload) {
      state.recipient = payload
    },



    setEmail_validation(state, payload) {
      state.email_validation = payload
    },
    setStartEmailValidation(state, payload) {
      state.start_email_validation = payload
    },
    //generate group
    setGenerateGroup(state, payload) {
      state.generateGroup = payload
    },


    //language

    setLanguage(state, payload) {
      state.language = payload
    },
    //political party
    setPoliticalParty(state, payload) {
      state.political_party = payload
    },
    //socialProgram
    setSocialProgram(state, payload) {
      state.social_program = payload
    },
    //title
    setTitle(state, payload) {
      state.title = payload
    },
    //quarter
    setQuarter(state, payload) {
      state.quarter = payload
    },
    //province
    setProvince(state, payload) {
      state.province = payload
    },
    //supportStrngt
    setSupportStrength(state, payload) {
      state.support_strength = payload
    },
    //country 
    setCountry(state, payload) {
      state.country = payload
    },
    //district
    setDistrict(state, payload) {
      state.district = payload
    },
    //department
    setDepartment(state, payload) {
      state.department = payload
    },
    //region
    setRegion(state, payload) {
      state.region = payload
    },
    //groupFilter
    setGroupFilter(state, payload) {
      state.group_filter = payload
    },
    setCollection(state, payload) {
      state.collection = payload
    },
    setCollectionGroup(state, payload) {
      state.collectionGroup = payload
    },
    setRecipientGroupList(state, payload) {
      state.recipientGroupList = payload
    },
    setSearchGroup(state, payload) {
      state.searchGroup = payload
    },
    setCheckGroups(state, payload) {
      state.checkGroups = payload
    },
    
  },
};