<template>
  <navBar :routes="routes" />
</template>

<script>
import navBar from "./customNavbar";
export default {
  name: "Engagement-nav",
  components: { navBar },
  computed: {
    routes() {
      return [
        { route: "/administration", name: "Profile" },
        {
          route: "/administration/reportingGroups",
          name: this.$t("Navigation.ReportingGroups"),
        },
        {
          route: "/administration/users",
          name: this.$t("Navigation.Users"),
        },
        {
          route: "/administration/roles",
          name: this.$t("Navigation.Roles"),
        },
        {
          route: "/administration/targetingAdmin",
          name: this.$t("Navigation.dataDictionary"),
        },
      ];
    },
  },
};
</script>
  