import { store } from "../store";
export default (to, from, next) => {
  if (store.getters.user) {
    //alert()
    if (to.matched.some((record) => record.meta.is_admin)) { 
      let user = store.getters.userDetail;
      let currentRolePrivilege = store.state.Main.currentRolePrivilege
      if (typeof user !== undefined && user.data && currentRolePrivilege.data.map(t=>t.privileges.name).includes("PRIV_ACTION_ADMIN")) {
        next();
      } else {
        next({ name: "Home" });
      }
    } else {
      next();
    }
  } else {
    next("/login");
  }
};
