<template>
  <div>
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <a class="navbar-brand" href="/" style="color: black">{{
          $route.name
        }}</a>
        <!-- <a class="navbar-brand" href="/" style="color: black">Dashboard</a> -->
        <button
          type="button"
          class="navbar-toggler navbar-toggler-right"
          :class="{ toggled: $sidebar.showSidebar }"
          aria-controls="navigation-index"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="toggleSidebar"
        >
          <span class="navbar-toggler-bar burger-lines"></span>
          <span class="navbar-toggler-bar burger-lines"></span>
          <span class="navbar-toggler-bar burger-lines"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <span class="nav-link" style="color: black">
                <!-- User Name -->
              </span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <TargetingNav v-if="$route.name == 'Targeting'" />
    <CampaignNav v-else-if="$route.name == 'Campaign'" />
    <EngagementNav v-else-if="$route.name == 'Engagement'" />
    <AdministrationNav v-else-if="$route.name == 'Administration'" />
  </div>
</template>
  <script>
import TargetingNav from "@/components/navigationsBar/TargetingNav.vue";
import CampaignNav from "@/components/navigationsBar/CampaignNav.vue";
import EngagementNav from "@/components/navigationsBar/EngagementNav.vue";
import AdministrationNav from "@/components/navigationsBar/AdministrationNav.vue";

export default {
  components: {
    TargetingNav,
    CampaignNav,
    EngagementNav,
    AdministrationNav,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    userDetail() {},
  },
  data() {
    return {
      activeNotifications: false,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  },
};
</script>
