import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/lang/locale/en.json'
import fr from'@/lang/locale/fr.json'

const dateTimeFormats = {
  'en': {
    date_only: { year: 'numeric',  month: 'short',  day: 'numeric'},
    hour_minute: { month: 'short', day: 'numeric', hour: 'numeric',  minute: 'numeric', hour12: false},
    short: { year: 'numeric',  month: 'short',  day: 'numeric',  hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false},
    long: { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true}
   },
 'fr': {
    date_only: { year: 'numeric', month: 'short', day: 'numeric'},
    hour_minute: { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false},
    short: { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false},
    long: { year: 'numeric', month: 'long', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric', second: 'numeric'}
  }
}
const numberFormats = {
  'en': {
    currency: {
      style: 'currency', currency: 'USD',delimiter: ","
      , separator: "."
    },
    percent: {
      style: 'percent'
    }
  },
  'fr': { 
    currency: {
      style: 'currency', currency: 'EUR',currency: 'USD', delimiter: ","
      , separator: "."
    },
    percent: {
      style: 'percent'
    },
    number:{
      style: 'decimal',useGrouping:true,locales:'en'
    }
  }
}

Vue.use(VueI18n)
const i18n = new VueI18n({
     silentFallbackWarn: true,
    locale: 'fr', 
    fallbackLocale: 'fr',
    messages: {en,fr},
    dateTimeFormats, // set date and time formats
    numberFormats // set number formats
  })

export default i18n;
