// import VTooltip from 'v-tooltip'
// Notifications plugin
import Notifications from '@/components/NotificationPlugin'
// A plugin file where you could register global components used across the app
// import GlobalComponents from './globalComponents'
// A plugin file where you could register global directives
import GlobalDirectives from './globalDirectives'
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from '@/components/SidebarPlugin'


// asset imports
import 'bootstrap/dist/css/bootstrap.css'
import './assets/sass/proximitydashboard.scss'
import './assets/css/styles.css'

/**
 * This is the main Dashboard Vue plugin where dashboard related plugins are registerd.
 */
export default {
  install(Vue) {
    // Vue.use(GlobalComponents)
    // Vue.use(GlobalDirectives)
    Vue.use(SideBar)
    Vue.use(Notifications)
    // Vue.use(VTooltip)
  }
}
