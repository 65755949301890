import { sendTransaction } from "../transactionParser";

const initialState = () => ({
   group             :null,
  });

  const state = initialState();

  export default {
    namespaced: true,
    // module assets
    state,
    getters: {
      //GroupTable
      group(state){
        return state.group
      }, 
    },
    
    actions: {      
      callResetState({ commit }) {
        commit("setState");
      },
      //Group
      callGroup({commit}, payload){
        sendTransaction(this,commit,"callGroup","group", payload);
      }, 
    },
    mutations: {      
      setState(state) {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
          state[key] = newState[key];
        });
      }, 
     //group
    setGroup(state,payload){
      state.group = payload
    },
    },
  };