<template>
  <b-navbar-nav class="et-hero-tabs-container" style="color: black">
    <li :class="liClass(el.route)" v-for="el in routes" :key="el.route">
      <router-link :to="el.route" exact class="text-link__align">
        <b-nav-item :href="el.route">{{ el.name }}</b-nav-item>
      </router-link>
    </li>
  </b-navbar-nav>
</template>
  
  <script>
export default {
  name: "customNavbar-nav",
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },

  methods: {
    liClass(path) {
      return { "et-hero-tab": true, active: this.$route.path == path };
    },
  },
};
</script>
  
<style scoped>
@import "../../assets/css/top_menu_nav_style.css";
</style>
 
/* .et-hero-tabs-container li.active::after {
    display: block;
    content: "";
    border-bottom: 3px solid #288754;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  .et-hero-tabs-container li:hover::after {
    transform: scaleX(1);
  }
  .et-hero-tabs-container li.active::after {
    transform-origin: 100% 50%;
  }
  .et-hero-tabs-container li::after {
    transform-origin: 0% 50%;
  } */
</style>