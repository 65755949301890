import { request, mediaRequest } from '@/store/services/api'
export const sendFile = function (that, commit, name, param) {
  var store = null;
  var storeData = null;
  commit("setLoading", true);
  commit("clearError");
  if (!mediaRequest) {
    commit("setError", "error passing call to backend");
    return;
  }
  let formData = new FormData();
  formData.append("target_type", param.target_type);
  param.photo ? formData.append("photo", param.photo) : param.audio? formData.append("audio", param.audio) : formData.append("attachment", param.attachment);
  mediaRequest(that.getters.bridgeConfig.upload_url)
    .post("", formData)
    .then(response => {
      if (
        typeof response.data.error === "undefined" ||
        response.data.error === "0"
      ) {
        storeData = response.data;
        param.id ? storeData.id = param.id : ''; 
        store = "setUploadFile";
        commit(store, storeData);
        commit("clearError");
      } else {
        if (response.data.message !== undefined)
          commit("setError", name + ": " + response.data.message, {
            root: true
          });
      }
      commit("setLoading", false);
    })
    .catch(error => {
      commit("setLoading", false);
      if (!error.status) {
        commit("setError", error, { root: true });
      }
    });
};
export const sendTransaction = function (that, commit, name, page, param) {
  var store = null;
  var storeData = null;
  if (param.action != "edit" && name != "callDownloadTransactions")
    commit("setLoading", true, { root: true });
  commit("clearError", null, { root: true });
  if (!request) {
    commit("setError", "error passing call to backend");
    return;
  }
  request(that.getters.bridgeConfig.bridge_url)
    .post("", param, {
      params: {
        p: page,
        user: param.force
      }
    })
    .then(response => {
      // datatables do not return error field when no error
      // other web services return error = 0 when everything is fine
      if (
        typeof response.data.error === "undefined" ||
        response.data.error === 0
      ) {
        //saving the data in the vuex store
        storeData = response.data;
        switch (name) {
          case "signin":
            store = "setUser";
            storeData = {
              username: param.username,
              token: response.data.token,
              user_id: response.data.user_id
            };
            break;
          case "callOverview":
            store = "Campaign/setOverview";
            break;

          case "callActive":
            store = "Campaign/setActive";
            break;
          case "callPending":
            store = "Campaign/setPending";
            break;
          case "callRobocallStatus":
            store = "Campaign/setRobocallStatus";
            break;
          case "callTrafficTrend":
            store = "Campaign/setTrafficTrend";
            break;

          case "callSmsStatus":
            store = "Campaign/setSmsStatus";
            break;
          case "callEmailStatus":
            store = "Campaign/setEmailStatus";
            break;
          case "callMissionActivity":
            store = "Campaign/setMissionActivity";
            break;
          case "callCampaignStatus":
            store = "Campaign/setCampaignStatus";
            break;
          case "callCampaign":
            store = "Campaign/setCampaign";
            break;
          case "callReportingGroupList":
            store = "Campaign/setReportingGroupList";
            break;
          case "callMissionTemplate":
            store = "Campaign/setMissionTemplate";
            break;
          case "callSurveyTemplate":
            store = "Campaign/setSurveyTemplate";
            break;
          case "callRecipientLocation":
            storeData.id = param.id;
            store = "Campaign/setRecipientLocation";
            break;
          case "callMissionRecipientLocation":
              storeData.id = param.id;
              store = "Campaign/setMissionRecipientLocation";
              break;  
          case "callMissionStatus":
            store = "Campaign/setMissionStatus";
            break;
          case "callMission":
            store = "Campaign/setMission";
            break;
          case "callMissionList":
            store = "Campaign/setMissionList";

            break;
          case "callCampaignDetail":
            store = "Campaign/setCampaignDetail";
            break;
          case "callEditUser":
            break;
          case "callDeleteUser":
            break;
          case "callNotification":
            store = "Report/setNotification";
            break;
          case "callAckNotification":
            store = "Report/setAckNotification";
            break;
          case "callReportLog":
            store = "Report/setReportLog";
            break;

          case "callReports":
            store = "Report/setReports";
            break;
          case "callReportTemplate":
            store = "Report/setReportTemplate";
            break;
          case "callReportEmail":
            store = "Report/setReportEmail";
            break;
          case "callAbonneByFact":
            storeData.id = param.id;
            store = "Transaction/setAbonneByFact";
            break;
          case "callGroupHealth":
            store = 'Targeting/setGroupHealth';
            break;
          case "callAmbassador":
            store = 'Targeting/setAmbassador';
            break;
          case "callPeople":
            store = 'People/setPeople';
            break;
          case "callError":
            store = 'People/setError';
            break;
          case "callEmailValidation":
            store = 'EmailValidation/setEmailValidation';
            break;
          case "callGroup":
            store = 'Targeting/setGroup';
            break;
          case "callLastGroup":
            store = 'RecentGroupe/setLastGroup';
            break;
          case "callRecipient":
            store = 'Targeting/setRecipient';
            break;
          case "callError":
            store = 'Targeting/setError';
            break;
          case "callStartEmailValidation":
            store = 'Targeting/setStartEmailValidation';
            break;
          case "callEmail_validation":
            store = 'Targeting/setEmail_validation';
            break;
          case "callGenerateGroup":
            store = 'Targeting/setGenerateGroup';
            break;
          case "callTrafficTrendDetails":
            store = 'Engagement/setTrafficTrendDetails';
            break;
          case "callWidgetLastSms":
            store = 'Engagement/setWidget_last_sms';
            break;
          case "callLanguage":
            store = "Targeting/setLanguage"
            break;
          case "callPoliticalParty":
            store = 'Targeting/setPoliticalParty';
            break;
          case "callSocialProgram":
            store = "Targeting/setSocialProgram";
            break;
          case "callTitle":
            store = "Targeting/setTitle";
            break;
          case "callQuarter":
            store = "Targeting/setQuarter";
            break;
          case "callProvince":
            store = "Targeting/setProvince";
            break;
          case "callRegion":
            store = "Targeting/setRegion"
            break;
          case "callSupportStrenght":
            store = "Targeting/setSupportStrength";
            break;
          case "callAppser_columns":
            store = "Targeting/setApp_columns_labels";
            break;
          case "callApp_users":
            store = "Targeting/setApp_users";
            break;
          case "callUser_columns":
            store = "Targeting/setUserColumn";
            break;
          case "callCountry":
            store = "Targeting/setCountry";
            break;
          case "callDistrict":
            store = "Targeting/setDistrict";
            break;
          case "callDepartment":
            store = "Targeting/setDepartment";
            break;
          case "callRecipientgroup":
            store = "Targeting/setRecipientgroup";
            break;
          case "callStop_email_validation":
            store = "Targeting/setStop_email_validation";
            break;
          case "callEmail_validation_status":
            store = "Targeting/setEmail_validation_status";
            break;
          case "callGroupFilter":
            store = "Targeting/setGroupFilter";
            break;
          case "callAge_stats":
            store = "Targeting/setAge_stats";
            break;
          case "callReportingGroupList":
            store = "Campaign/setReportingGroupList";
            break;
          case "callDownloadData":
            store = 'Targeting/setDownloadData';
            break;
          case "callAmbassador_engagement":
            store = 'Engagement/setAmbassador_engagement';
            break;
          case "callCsvtogroup":
            store = 'Targeting/setCsvtogroup';
            break;
          case "callGet_timezone":
            store = 'Targeting/setGet_timezone';
            break;
          case "callEmailEvaluation":
            store = 'Engagement/setEmailEvaluation';
            break;
          case "callSmsEvaluation":
            store = 'Engagement/setSmsEvaluation';
            break;
          case "callFrom":
            store = 'Engagement/setFrom';
            break;
          case "callSendMessage":
            store = 'Engagement/setSendMessage';
            break;
          case "callDomain":
            store = 'Campaign/setDomain';
            break;
          case "callMissionStats":
            storeData.id = param.id;
            store = "Campaign/setMissionStats";
            break;
          case "callCollection":
            store = "Targeting/setCollection";
            break;
          case "callCollectionGroup":
            store = "Targeting/setCollectionGroup";
            break;
          case "callRecipientGroupList":
            store = "Targeting/setRecipientGroupList";
            break;
          case "callSearchGroup":
            store = "Targeting/setSearchGroup";
            break;
            case "callDeleteGroup_Recipient":
              storeData.id = param.id;
              store = "setDelete";
            break; 
          case "callCheckGroups":
            store = "Targeting/setCheckGroups";
            break;
            case "callAttachments":
              store = "Campaign/setAttachments";
              break;
          case "callMissionDetail":
            storeData.id = param.id;
            store = "Campaign/setMissionDetail";
          break; 
                       
          default:
            commit("setError", "Error: action " + name + " not supported", {
              root: true
            });
        }

        if (param.action != undefined) {
          storeData.name = name; 
          if (param.action == "create") {
            param.data[0].id ? storeData.id = param.data[0].id  : ''; 
            store = "setCreate";
          } else if (param.action == "remove") {
            store = "setDelete";
          } else if (param.action == "edit") {
            store = "setEdit";
          }
        }

        commit(store, storeData, { root: true });
        commit("clearError", null, { root: true });
      } else {
        // User already Login
        if (response.data.error_msg == "User not login") {
          if (that.state.error == undefined) {
            commit("setError", "User not login", { root: true });
            //this.$router.push("/login");
            //that.dispatch("callResetState");
            // commit('resetState',{root:true})
          }
        } else if (response.data.error_msg != undefined)
          commit("setError", name + ": " + response.data.error_msg, {
            root: true
          });
        else if (response.data.error != undefined)
          commit("setError", name + " : " + response.data.error, {
            root: true
          });
      }
      commit("setLoading", false, { root: true });
    })
    .catch(error => {
      commit("setLoading", false, { root: true });
      if (error && !error.status) {
        console.log("m gen error", error);
        error = JSON.stringify(error.message);
        commit("setError", error, { root: true });
      }
    });
};
