var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._b({tag:"component",attrs:{"custom":""},nativeOn:{"click":function($event){return _vm.hideSidebar.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
var href = ref.href;
return [_c('li',{class:{
      'router-link-exact-active': isExactActive || _vm.$attrs.exact == false,
      'router-link-active': isActive,
    }},[_c('a',{staticClass:"nav-link",attrs:{"href":href}},[_vm._t("default",function(){return [(_vm.link.icon)?_c('i',{class:_vm.link.icon}):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.link.name))])]})],2)])]}}],null,true)},'component',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }