import { sendTransaction } from "../transactionParser";

const initialState = () => ({
    people         :null,
    total          :null,
    completed      :null,
    below          :null,
    telephone      :null,
    email          :null,
    email_validated:null,
    email_valid    :null,
    error          :null,
    error_msg      :null
  });

  const state = initialState();

  export default {
    namespaced: true,
    // module assets
    state,
    getters: { 

      people(state){
         return state.people
      },
      total(state) {
        return state.total
      },
      completed(state) {
        return state.completed
      },
      below(state){
          return state.below
      },
      telephone(state){
          return state.telephone
      },
      email(state){
          return state.email
      },
      email_validated(state){
          return state.email_validated
      },
      email_valid(state){
          return state.email_valid
      },
      error(state){
        return state.error
       },
      error_msg(state){
          return state.error_msg
      }
    },
    actions: {
      callResetState({ commit }) {
        commit("setState");
      },
      callPeople({ commit }, payload) {  
        sendTransaction(this, commit, "callPeople","widget_database_health", payload); 
      },
      callError({commit}, payload){
        sendTransaction(this,commit,"callError","error",payload);
      },
      callErrorMsg({commit}, payload){
        sendTransaction(this,commit,"callErrorMsg","error_msg",payload);
      },
      callTotal({ commit }, payload) {
        sendTransaction(this, commit, "callTotal", "total", payload);
      },

      callCompleted({ commit }, payload) {
        sendTransaction(this, commit, "callCompleted", "completed", payload);
      },

      callBelow({ commit }, payload) {
        sendTransaction(this, commit, "callBelow", "below", payload);
      },
       
      
      callEmailValid({commit}, payload){
        sendTransaction(this,commit,"callEmailValid","email_valid", payload);
      }
    },
    mutations: {      
      setState(state) {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
          state[key] = newState[key];
        });
      },
      setPeople(state,payload){
          state.people = payload
      },
      setTotal(state, payload) {       
        state.total = payload       
      },
      setCompleted(state, payload) {
         state.completed = payload
      },
      setBelow(state, payload){
          state.below = payload
      }, 
      setTelephone(state,payload){
          state.telephone = payload
      },
      setEmail(state, payload){
          state.email = payload
      },
      setEmailValidated(state, payload){
          state.email_validated = payload
      },
      setEmailValid(state, payload){
          state.email_valid = payload
      },
      setError(state, payload){
          state.error = payload
      },
      setErrorMsg(state, payload){
        state.error_msg = payload
      }
    }
  };