import { sendTransaction } from "../transactionParser";

const initialState = () => ({ 
    overview: null,
    active:null,
    pending:null,
    trafficTrend:null,
    robocallStatus: null,
    smsStatus:null,
    emailStatus:null,
    missionActivity:null,
    campaignStatus:null,
    missionStatus:null,
    recipientLocation:null,
    missionRecipientLocation:null,
    campaign:null,
    reportingGroup:null, 
    reporting_group_list: null,
    mission_template: null,
    missionList: null,
    campaign_detail:null,
    editItem: null,
    survey_template: null,
    domain:null,
    missionStats: null,
    attachments: null,
    mission: null,
    missionDetail: null,

  });
  const state = initialState();
  export default {
    namespaced: true,
    // module assets
    state,
    getters: { 
      overview(state) {
        return state.overview
      },
      pending(state){
        return state.pending
      },
      active(state){
        return state.active
      },
      trafficTrend(state) {
        return state.trafficTrend
      },
      robocallStatus(state) {
        return state.robocallStatus
      },
      smsStatus(state){
        return state.smsStatus
      },
      emailStatus(state){
        return state.emailStatus
      },
      missionActivity(state){
        return state.missionActivity
      },
      campaignStatus(state){
        return state.campaignStatus
      },
      missionStatus(state){
        return state.missionStatus
      },
      recipientLocation(state){
        return state.recipientLocation
      },
      missionRecipientLocation(state){
        return state.missionRecipientLocation
      },
      campaign(state){
        return state.campaign
      },
      reporting_group_list(state){
         return state.reporting_group_list
      }, 
      mission(state){
        return state.mission 
      },
      mission_template(state){
        return state.mission_template
      },
      campaign_detail(state){
        return state.campaign_detail
      },
      missionList(state){
        return state.missionList 
      },
      editItem(state){
        return state.editItem 
      },
      survey_template(state){
        return state.survey_template
      },
      domain(state){
        return state.domain
      },
      missionStats(state){
        return state.missionStats
      },
      attachments(state){
        return state.attachments
      },
      emailStatus(state){
        return state.emailStatus
      },
      missionDetail(state){
        return state.missionDetail
      },

      
    },
    actions: {
      
      callResetState({ commit }) {
        commit("setState");
      },      
      callReportingGroupList({ commit }, payload) {
        sendTransaction(this, commit, "callReportingGroupList", "reporting_group_list", payload);
      },
      callOverview({ commit }, payload) {
        sendTransaction(this, commit, "callOverview", "overview", payload);
      },
      callActive({ commit }, payload) {  
        sendTransaction(this, commit, "callActive", "active", payload); 
      },
      callPending({ commit }, payload) {
        sendTransaction(this, commit, "callPending", "pending", payload); 
      },
      callTrafficTrend({ commit }, payload) {
        sendTransaction(this, commit, "callTrafficTrend", "traffic_trend", payload);
      },
      callRobocallStatus({ commit }, payload) {
        sendTransaction(this, commit, "callRobocallStatus", "widget_robocall_status", payload);
      },
      callSmsStatus({ commit }, payload) {  
        sendTransaction(this, commit, "callSmsStatus", "widget_sms_status", payload); 
      },
      callEmailStatus({ commit }, payload) {  
        sendTransaction(this, commit, "callEmailStatus", "widget_email_status", payload); 
      },
      callEmailStatus({ commit }, payload) {  
        sendTransaction(this, commit, "callEmailStatus", "widget_email_status", payload); 
      },
      callMissionActivity({ commit }, payload) {  
        sendTransaction(this, commit, "callMissionActivity", "widget_mission_activity", payload); 
      },
      callCampaignStatus({ commit }, payload) {  
        sendTransaction(this, commit, "callCampaignStatus", "widget_campaign_status", payload); 
      },
      callMissionStatus({ commit }, payload) {  
        sendTransaction(this, commit, "callMissionStatus", "widget_mission_status", payload); 
      },
      callRecipientLocation({ commit }, payload) {  
        sendTransaction(this, commit, "callRecipientLocation", "widget_recipients_location", payload); 
      },
      callMissionRecipientLocation({ commit }, payload) {  
        sendTransaction(this, commit, "callMissionRecipientLocation", "recipients_location", payload); 
      },
      callCampaign({ commit }, payload) {  
        sendTransaction(this, commit, "callCampaign", "campaign", payload); 
      },
      callMissionTemplate({ commit }, payload) {
        sendTransaction(this, commit, "callMissionTemplate", "mission_template", payload); 
      },
      callMission({ commit }, payload) {  
        sendTransaction(this, commit, "callMission", "mission", payload); 
      },
      callMissionList({ commit }, payload) {  
        sendTransaction(this, commit, "callMissionList", "mission_list", payload); 
      },
      callCampaignDetail({commit}, payload){
        sendTransaction(this, commit, "callCampaignDetail", "campaign_detail", payload)
      },
      callEditItem({ commit }, payload) {
        commit("setEditItem",payload);
      },  
      callDomain({ commit }, payload) {  
        sendTransaction(this, commit, "callDomain", "domain", payload); 
      },
      callMissionStats({ commit }, payload) {  
        sendTransaction(this, commit, "callMissionStats", "mission_stats", payload); 
      },
      callAttachments({ commit }, payload) {  
        sendTransaction(this, commit, "callAttachments", "attachment", payload); 
      },
      callMissionDetail({ commit }, payload) {  
        sendTransaction(this, commit, "callMissionDetail", "mission_detail", payload); 
      },
      callResetEditItem({ commit }) { 
         commit("setEditItem", null); 
       },
       callResetMissionTemplate({ commit }) { 
        commit("setMissionTemplate", null); 
      },
      callSurveyTemplate({ commit }, payload) {
        sendTransaction(this, commit, "callSurveyTemplate", "survey_template", payload); 
      },
      callResetSurveyTemplate({ commit }) { 
        commit("setSurveyTemplate", null); 
      },      
    },
    mutations: {      
      setState(state) {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
          state[key] = newState[key];
        });
      }, 
      setOverview(state, payload) {       
        state.overview = payload       
      }, 
      setReportingGroupList(state, payload){
         state.reporting_group_list = payload
      },
      setActive(state, payload) {       
        state.active = payload       
      },
      setPending(state, payload) {       
        state.pending = payload       
      },
      setRobocallStatus(state, payload) {       
        state.robocallStatus = payload       
      }, 
      setTrafficTrend(state, payload) {       
        state.trafficTrend = payload       
      }, 
      setSmsStatus(state, payload) {       
        state.smsStatus = payload       
      },
      setEmailStatus(state, payload) {       
        state.emailStatus = payload       
      },
      setMissionActivity(state, payload) {       
        state.missionActivity = payload       
      },
      setCampaignStatus(state, payload) {       
        state.campaignStatus = payload       
      },
      setMissionStatus(state, payload) {       
        state.missionStatus = payload       
      },
      setRecipientLocation(state, payload) { 
        state.recipientLocation = payload       
      },
      setMissionRecipientLocation(state, payload) { 
        state.missionRecipientLocation = payload       
      },
      setCampaign(state, payload) {       
        state.campaign = payload       
      },
      setReportingGroup(state, payload) {       
        state.reportingGroup = payload       
      },
      setMission(state, payload) {       
        state.mission = payload       
      },
      setMissionTemplate(state, payload){
        state.mission_template = payload
      }, 
      setCampaignDetail(state, payload){
       state.campaign_detail = payload
      },
      setMissionList(state, payload) {       
        state.missionList = payload       
      },      
      setEditItem(state, payload) {       
        state.editItem = payload       
      },
      setSurveyTemplate(state, payload){
        state.survey_template = payload
      },   
      setDomain(state, payload){
        state.domain = payload
      }, 
      setMissionStats(state, payload) {       
        state.missionStats = payload       
      }, 
      setAttachments(state, payload) {       
        state.attachments = payload       
      }, 
      setMissionDetail(state, payload) {       
        state.missionDetail = payload       
      }
    },
  };