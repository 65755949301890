<template>
  <navBar :routes="routes" />
</template>

<script>
import navBar from "./customNavbar";
export default {
  name: "Engagement-nav",
  components: { navBar },

  computed: {
    routes() {
      return [
        { route: "/engagement", name: this.$t("Navigation.Home") },
        {
          route: "/engagement/ambassador",
          name: this.$t("Navigation.Ambassador"),
        },
        {
          route: "/engagement/messaging",
          name: this.$t("Navigation.Messaging"),
        },
        {
          route: "/engagement/MessagingInbox",
          name: this.$t("Navigation.MessageInbox"),
        },
        {
          route: "/engagement/maillingInbox",
          name: this.$t("Navigation.MailBox"),
        },
      ];
    },
  },
};
</script>