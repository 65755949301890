import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import router from "@/router/router";
import { store } from "@/store";
import Notifications from "vue-notification";
import vueTopprogress from "vue-top-progress";
import i18n from "@/lang/lang";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4lang_fr_FR from "@amcharts/amcharts4/lang/fr_FR";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import DataTables from 'v3w-lib'
import { abilitiesPlugin } from "@casl/vue";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import 'vue-select/dist/vue-select.css';
import * as moment from "moment";
import 'moment/locale/fr';
import Vuelidate from 'vuelidate';
import CKEditor from 'ckeditor4-vue';
import { VueCsvImportPlugin } from "vue-csv-import";
import vSelect from 'vue-select';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import ProximityBootstrap from './proximity-main'
Vue.use(ProximityBootstrap)

// Require Froala Editor js file.

let globalData = new Vue({
  data: { $showBigNumber: false }
});
Vue.mixin({
  methods: {
    $toLocalString(number, { local, minFracDigit = 2, maxFracDigit = 2 }) {
      return Number(number).toLocaleString(local, { minimumFractionDigits: minFracDigit, maximumFractionDigits: maxFracDigit })
    }
  },
  computed: {
    $showBigNumber: {
      get: function () { return globalData.$data.$showBigNumber },
      set: function (value) { globalData.$data.$showBigNumber = value; }
    }
  }
})

Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
Vue.component('v-select', vSelect)

Object.defineProperty(Vue.prototype, "$am4core", { value: am4core });
Object.defineProperty(Vue.prototype, "$am4lang_fr_FR", {
  value: am4lang_fr_FR
});
Object.defineProperty(Vue.prototype, "$am4charts", { value: am4charts });
Object.defineProperty(Vue.prototype, "$am4maps", { value: am4maps });
Object.defineProperty(Vue.prototype, "$am4themes_animated", {
  value: am4themes_animated
});

Vue.prototype.$theme = ["light"];
Vue.prototype.$Number = function (number) {
  return new Intl.NumberFormat("de").format(number);
};
am4core.options.commercialLicense = true;

Vue.prototype.$moment = moment;

Vue.use(vueTopprogress);
Vue.use(BootstrapVue);
Vue.use(Notifications);
Vue.use(DataTables, { store });
Vue.use(abilitiesPlugin, store.getters["Auth/ability"]);


Vue.prototype.$UploadUrl = store.getters.bridgeConfig.baseUrl
Vue.use(Vuelidate);
Vue.use(CKEditor);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueCsvImportPlugin);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
