import { sendTransaction } from "../transactionParser";

const initialState = () => ({ 
   lastGroup       :null,
   name            :null,
   size            :null,
   email           :null,
   tel             :null
  });

  const state = initialState(); 

  export default {
    namespaced: true,
    // module assets
    state,
    getters: { 
      lastGroup(state) {
        return state.lastGroup
      },
      name(state) {
        return state.name
      },
      size(state){
          return state.size
      },
      email(state){
          return state.email
      },
      tel(state){
          return state.tel
      } 
    
    },
    actions: {      
      callResetState({ commit }) {
        commit("setState1");
      },  
      callLastGroup({ commit }, payload) {
        sendTransaction(this, commit, "callLastGroup","widget_last_group", payload);
      },

      callName({ commit }, payload) {
        sendTransaction(this, commit, "callName", "data.name", payload);
      },

      callSize({ commit }, payload) {
        sendTransaction(this, commit, "callSize", "data.size", payload);
      },

      callEmail({ commit }, payload) {
        sendTransaction(this, commit, "callEmail", "data.email", payload);
      },
      callTel({ commit }, payload) {
        sendTransaction(this, commit, "callTel", "data.tel", payload);
      },
      callError({commit}, payload){
          sendTransaction(this,commit,"callError","error", payload);
      },
      callErrorMsg({commit}, payload){
        sendTransaction(this,commit,"callErrorMsg","error_msg", payload);
      }
    },
    mutations: {      
      setState(state) {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
          state[key] = newState[key];
        });
      }, 
      setLastGroup(state, payload) {       
        state.lastGroup = payload       
      },
      setSize(state, payload) {
         state.size = payload
      },
      setEmail(state, payload){
          state.email = payload
      }, 
      setTel(state,payload){
          state.tel = payload
      },
      setDate(state, payload){
          state.date = payload
      },
      setName(state, payload){
          state.name = payload
      } 
    },
  };