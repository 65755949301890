import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import modules from "@/store/modules";
import router from "../router/router";
import { sendTransaction, sendFile } from "@/store/transactionParser";
import i18n from '@/lang/lang';
export const strict = false
Vue.use(Vuex);
//this blocks parse the localstroage , check if verison of store different if so delete
// old and create new for the persitedstafe
const appVersion = process.env.VUE_APP_PACKAGE_JSON
  ? process.env.VUE_APP_PACKAGE_JSON
  : "";
const persistedstateversion = "proximity" + appVersion;
const arrayofStorage = Object.entries(localStorage)
  .map((x) => x[0])
  .filter((y) => y.substring(0, 8) == "proximity");
arrayofStorage.forEach((element) => {
  element !== persistedstateversion
    ? window.localStorage.removeItem(element)
    : "";
});

export const store = new Vuex.Store({
  modules,
  state: {
    navbar: null,
    create: null,
    edit: null,
    delete: null,
    operator: null,
    facturier: null,
    logout: null,
    willReset: false
  },
  mutations: {
    setLocaleLang(state, payload) {
      i18n.locale = payload
      state.loadedLanguages = payload
    },
    setGlobalSearch(state, payload) {
      state.globalSearch = payload;
    },
    setCreate(state, payload) {
      state.create = payload;
    },
    setEdit(state, payload) {
      state.edit = payload;
    },
    setDelete(state, payload) {
      state.delete = payload;
    },
    setUploadFile(state, payload) {
      state.uploadFile = payload;
    },
    setNavbar(state, payload) {
      state.navbar = payload
    },
    resetState(state) {
      state.operator = null;
      state.globalSearch = null;
      state.create = null;
      state.edit = null;
      state.delete = null;
      state.overviewgraph = false;
      state.error = null;
      state.uploadFile = null;
      state.willReset = false
      state.navbar = null
      state.logout = null
    },

    //new added for apix
    setOperators(state, payload) {
      state.operator = payload;
    },
    setFacturiers(state, payload) {
      state.facturier = payload
    },
    setWillReset(state, payload) {
      state.willReset = payload
    },
    setLogout(state, payload) {
      state.logout = payload
    }
  },
  actions: {
    callGlobalSearch({ commit }, payload) {
      commit("setGlobalSearch", payload);
    },
    callResetState({ commit }) {
      // router.push({ path: "/login" });
      commit("resetState");
      commit("setState"); //state of main module in library
      // resets state of all the modules
      Vue.$moduleNamespace().forEach((moduleName) => {
        commit(`${moduleName}setState`);
      });

    },
    callWillReset({ commit }, payload) {
      commit("setWillReset", payload)
    },
    callServiceActionReset({ commit }, payload) {
      const capsName = payload.name[0].toUpperCase() + payload.name.substring(1)
      commit(`set${capsName}`, payload.value)
    },
    //new added for apix
    callOperator({ commit }, payload) {
      sendTransaction(this, commit, "callOperator", "operator", payload);
    },
    callFacturier({ commit }, payload) {
      sendTransaction(this, commit, "callFacturier", "facturier", payload);
    },
    callNavbar({ commit }, payload) {
      commit('setNavbar', payload)
    },
    callWillReset({ commit }, payload) {
      commit("setWillReset", payload)
    },
    callUploadMediaAudio({ commit }, payload) {
      sendFile(this, commit, "callUploadMediaAudio", payload)
    },
    callUploadMediaFile({ commit }, payload) {
      sendFile(this, commit, "callUploadMediaFile", payload)
    },
  },
  getters: {
    create(state) {
      return state.create;
    }, loadedLanguages(state) {
      return state.loadedLanguages;
    },
    edit(state) {
      return state.edit;
    },
    delete(state) {
      return state.delete;
    },
    uploadFile(state) {
      return state.uploadFile;
    },
    //new added for apix
    operators(state) {
      return state.operator
    },
    facturier(state) {
      return state.facturier
    },
    logout(state) {
      return state.logout
    },
    clearError(state) {
      state.error = null
    },
    navbar(state) {
      return state.navbar
    },
    willReset(state) {
      return state.willReset
    }
  },
  strict: true,
  plugins: [
    createPersistedState({
      key: persistedstateversion,
      // overwrite: true,
    }),
  ],
});
