<template>
  <div
    id="app"
    :class="{
      [userDetail !== null ? userDetail.data[0].Users.theme : 'light']: true,

      'nav-open': $sidebar.showSidebar,
    }"
  >
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <component :is="layout"></component>
    <notifications group="foo" position="bottom right" />
  </div>
</template>

<script>
import FullScreenLayout from "@/components/layouts/FullScreenLayout";
import MainLayout from "@/components/layouts/MainLayout";

export default {
  components: {
    FullScreenLayout,
    MainLayout,
  },

  data() {
    return {
      resize: false,
      errorMsg: null,
    };
  },
  created() {},
  mounted() {
    if (process.env.NODE_ENV == "development") {
      let param = {
        filePath: "./config.json",
      };
      this.$store.dispatch("callSetBaseUrl", param);
    }
    this.$theme[0] =
      this.userDetail !== null ? this.userDetail.data[0].Users.theme : "light";
  },

  computed: {
    loading() {
      //checking existing loading status from staate
      return this.$store.getters.loading;
    },
    layout() {
      return this.$store.getters["Layout/layout"];
    },
    userDetail() {
      return this.$store.getters.userDetail;
    },
    error() {
      return this.$store.getters.error;
    },
    logout() {
      return this.$store.getters.logout;
    },
  },
  watch: {
    loading(value) {
      if (value == true) {
        this.$refs.topProgress.start();
      } else {
        this.$refs.topProgress.done();
      }
    },
    userDetail(value) {
      this.$theme[0] = value !== null ? value.data[0].Users.theme : "light";
    },
    logout(value) {
      if (value !== null && value !== undefined) {
        if (value == "Logout success") {
          this.$store.dispatch("callWillReset", true);
          this.$router.push("/login");
          this.$notify({
            group: "foo",
            type: "success",
            text: this.$t("NotificationMessage.logoutsucces"),
          });
        }
      }
    },
    error(value) {
      if (value !== null && value !== undefined && value != this.errorMsg) {
        this.errorMsg = value;
        let ErrorValue = value;
        if (value.includes("Integrity constraint")) {
          const regex = /:\s.*\s('.+')\s.*\s('.+')/gm;
          let result = regex.exec(value);
          ErrorValue =
            this.$tc("errors." + result[2].replace(/'/gi, "") + "_exist") +
            result[1];
          this.errorMsg = null;
        } else if (value == '"Network Error"') {
          ErrorValue = this.$t("errors.networkError");
        } else if (value == "User not login") {
          this.$store.dispatch("callWillReset", true);
          this.$router.push("/login");
        }
        this.$notify({
          group: "foo",
          type: "error",
          text: ErrorValue,
        });
      }
    },
  },
};
</script>

<style lang="scss">
// @import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
@import "../src/assets/css/theme.css";

#app {
  font-family: "Montserrat", "Avenir", Helvetica, Arial, sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  // background: #fdf6f6
}

.help {
  background-color: #ffff00;
  border-radius: 50%;
  color: #000;
  text-align: center;
  width: 15px;
  height: 15px;
  font-size: 10px;
  cursor: default;
}

.popover,
.popover-header,
.popover-body {
  //background-color: #ffff00 !important ;
  color: #000 !important;
  // border: 1px inset #fff;
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #ffff00 !important;
}

.date-time-picker {
  display: inline;
}

.date-time-picker input {
  border-color: lightgray;
  border-style: solid;
  border-radius: 5px;
  padding-left: 5px;
  width: 165px;
  color: #6485f7;
  border: none;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 12px;
}

.fa.fa-info-circle {
  padding-left: 5px;
}

.fa-1x {
  font-size: 10px;
  color: #e8cc23;
}

.widget__box-shadow {
  box-shadow: 0px 20px 31px rgba(221, 228, 237, 0.5) !important;
  border-radius: 10px !important;
}

.widget__box {
  border-radius: 10px !important;
}

.subject-table thead {
  /* // background: #f2f4f7; */

  border: 1px solid white;
  color: #7f8d9f;
}

section {
  min-width: 100% !important;
}

h4,
.text-medium {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.2rem;
}

.page-link {
  background: #fff !important;
  font-weight: bold;
  font-size: 0.98rem;
  color: #788bb1 !important;
  border: transparent !important;
}

.page-item.active .page-link {
  color: #252e61 !important;
  background-color: #fff !important;
  border-color: transparent !important;
}

.page-item:first-child .page-link {
  color: #252e61 !important;
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
}

.page-item:last-child .page-link {
  color: #252e61 !important;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}

.page-item:last-child(-1) .page-link,
.page-item:nth-child(2) .page-link {
  border-left: 1px solid #788bb1 !important;
  border-right: 1px solid #788bb1 !important;
  color: #252e61 !important;
}

.page-item.disabled.page-link {
  color: #788bb1 !important;
}

// table new style
#my-table {
  thead {
    th {
      font: normal normal bold 16px/21px DM Sans;
      letter-spacing: 0px;
      color: #89909c;
      opacity: 1;
      background: #ffffff;
      vertical-align: inherit !important;
    }
  }

  tr {
    height: 64px;
    font: normal normal normal 15px/20px DM Sans;
    letter-spacing: 0px;
    color: #8d949f;
    opacity: 1;

    td {
      vertical-align: middle !important;
    }
  }

  tr:nth-of-type(2n + 1) {
    background: #fafafa 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  // th:first-child > div,
  // td:first-child > div {
  //   margin-left: 31px !important;
  // }
  // th:last-child > div,
  // td:last-child > div {
  //   margin-right: 31px !important;
  // }
  td:last-child > div {
    // display: flex;
    // justify-content: right;
  }

  td .my-table-actions {
    width: 158px;
    height: 39px;
    background: #fcfcfc 0% 0% no-repeat padding-box;
    border: 1px solid #e3e4e5;
    border-radius: 5px;
    opacity: 1;
    display: flex;
    justify-content: center;

    span {
      height: auto !important;

      i:hover {
        color: #146cf3 !important;
      }
    }
  }
}

//  /* Medium devices (tablets, 768px and up)*/
//   @media (min-width: 768px) {
//   #my-table {
//    height: 697px !important;
//   }
//  }

// end table style
</style>
<style>
.card-no-border {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 10px #00000014 !important;
  border: none !important;
  border-radius: 10px !important;
  opacity: 1 !important;
}

.card-title {
  font: normal normal 500 16px/21px DM Sans !important;
  letter-spacing: 0px;
  color: #89909c;
}

body {
  overflow-x: hidden;
}
</style>