import { sendTransaction } from "../transactionParser";

const initialState = () => ({ 
   trafficTrendDetails  :null,
   widget_last_sms      :null,
   ambassador_engagement:null,
   send_message         :null,
   sms_evaluation       :null,
   email_evaluation     :null,
   from                 :null, 

  });
  const state = initialState();
  export default {
    namespaced: true,
    //module assets
    state,
    getters: { 
        trafficTrendDetails(state) {
        return state.trafficTrendDetails
      },
      from(state){
        return state.from
      },
      widget_last_sms(state){
        return state.widget_last_sms
      },
      ambassador_engagement(state){
        return state.ambassador_engagement
      },
      send_message(state){
        return state.send_message 
      },
      email_evaluation(state){
        return state.email_evaluation
      },
      sms_evaluation(state){
        return state.sms_evaluation
      },
    },
    actions: {      
      callResetState({ commit }) {
        commit("setState");
      },
      callFrom({ commit }, payload) {
        sendTransaction(this, commit, "callFrom","from", payload); 
      },
      callTrafficTrendDetails({ commit }, payload) {
        sendTransaction(this, commit, "callTrafficTrendDetails","traffic_trend_details", payload); 
      },
      callEmailEvaluation({ commit }, payload) {
        sendTransaction(this, commit, "callEmailEvaluation","email_evaluation", payload); 
      },
      callSmsEvaluation({ commit }, payload) {
        sendTransaction(this, commit, "callSmsEvaluation","sms_evaluation", payload); 
      },
      callWidgetLastSms({ commit }, payload){
        sendTransaction( this, commit, "callWidgetLastSms", "widget_last_sms", payload);
      },
      callAmbassador_engagement({ commit }, payload){
        sendTransaction( this, commit, "callAmbassador_engagement", "ambassador_engagement", payload);
      },
      callSendMessage({ commit }, payload){
        sendTransaction( this, commit, "callSendMessage", "send_message", payload);
      },
      callResetEmailEvaluation({ commit }) { 
        commit("setEmailEvaluation", null); 
      },
    },
    mutations: {      
      setState(state) {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
          state[key] = newState[key];
        });
      }, 
      setTrafficTrendDetails(state, payload) {
        state.trafficTrendDetails = payload
      },
      setFrom(state, payload) {
        state.from = payload
      },
      setWidget_last_sms(state, payload){
        state.widget_last_sms = payload
      },
      setSendMessage(state, payload){
        state.send_message= payload
      },
      setSmsEvaluation(state, payload){
        state.sms_evaluation= payload
      },
      setEmailEvaluation(state, payload){
        state.email_evaluation= payload
      },
      setAmbassador_engagement(state,payload){
        state.ambassador_engagement = payload
      }
    },
  };