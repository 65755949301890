import { sendTransaction } from "../transactionParser";

const initialState = () => ({ 
  notification: null,
  ackNotification: null,
  reportLog: null, 
  locality: null,
  symptom: null,
  reports: null,
  reportTemplate:null,
  reportEmail:null,
});

const state = initialState();
export default {
  namespaced: true,
  // module assets
  state,

  getters: { 
    transaction(state) {
      return "je test";
    },
    notification(state) {
      return state.notification;
    },
    ackNotification() {
      return state.ackNotification;
    },
    reportLog(state) {
      return state.reportLog;
    }, 

    locality(state) {
      return state.locality;
    },

    symptom(state) {
      return state.symptom;
    },

    reports(state){
      return state.reports
    },
    reportTemplate(state){
      return state.reportTemplate
    },
    reportEmail(state){
      return state.reportEmail
    }
  },
  actions: {
    
    callResetState({ commit }) {
      commit("setState");
    },
    
    callNotification({ commit }, payload) {
      sendTransaction(
        this,
        commit,
        "callNotification",
        "notification",
        payload
      );
    },
    callAckNotification({ commit }, payload) {
      sendTransaction(
        this,
        commit,
        "callAckNotification",
        "ack_notification",
        payload
      );
    },
    callReportLog({ commit }, payload) {
      sendTransaction(this, commit, "callReportLog", "log", payload);
    },
     
    callSymptom({ commit }, payload) {
      sendTransaction(this, commit, "callSymptom", "symptom", payload);
    },
    callLocality({ commit }, payload) {
      sendTransaction(this, commit, "callLocality", "locality", payload);
    },

    callReports({ commit }, payload) {
      sendTransaction(this, commit, "callReports", "report", payload);
    },
    callReportTemplate({ commit }, payload) {
      if(payload.action == null){
        payload.draw = "1"
        payload.start = "0"
        payload.length = "0"
        payload.columns = [{"data":"report_templates.row","name":"","searchable":"true","orderable":"true","search":{"value":"","regex":"false"}}]
        payload.search = {"value":"","regex":""}
        payload.order = [{"column":"0","dir":"asc"}]
      }
      sendTransaction(this, commit, "callReportTemplate", "report_template", payload);
    },
    callReportEmail({ commit }, payload) {
      sendTransaction(this, commit, "callReportEmail", "report_email", payload);
    },
  },
  mutations: {
    
    setState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    }, 
    setNotification(state, payload) {
      state.notification = payload;
    },
    setAckNotification(state, payload) {
      state.ackNotification = payload;
    },
    setReportLog(state, payload) {
      state.reportLog = payload;
    },
    
    setLocality(state, payload) {
      state.locality = payload;
    },

    setSymptom(state, payload) {
      state.symptom = payload;
    },

    setReports(state, payload) {
      state.reports = payload;
    },

    setReportTemplate(state, payload) {
      state.reportTemplate = payload;
    },

    setReportEmail(state, payload) {
      state.reportEmail = payload;
    }
  },
};
