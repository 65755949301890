<template>
  <navBar :routes="routes" />
</template>

<script>
import navBar from "./customNavbar";
export default {
  name: "Targeting-nav",
  components: { navBar },

  computed: {
    routes() {
      return [
        {
          route: "/targeting",
          name: this.$t("Navigation.Home"),
        },
        { route: "/targeting/group", name: this.$t("Navigation.Groups") },
        {
          route: "/targeting/recipients",
          name: this.$t("Navigation.Recipients"),
        },
        {
          route: "/targeting/validation",
          name: this.$t("Navigation.Validation"),
        },
      ];
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/top_menu_nav_style.css";
</style>
<style >
/* .et-hero-tabs-container li.active::after {
  display: block;
  content: "";
  border-bottom: 3px solid #288754;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.et-hero-tabs-container li:hover::after {
  transform: scaleX(1);
}
.et-hero-tabs-container li.active::after {
  transform-origin: 100% 50%;
}
.et-hero-tabs-container li::after {
  transform-origin: 0% 50%;
} */
</style>