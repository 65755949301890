<template>
    <footer class="footer">
      <div class="container-fluid">
        <nav>
          <ul class="footer-menu">
            <li>
              <router-link :to="{path:'/admin'}">Dashboard</router-link>
            </li>
          </ul>
        </nav>
        <div class="copyright text-center">
          © {{year}}&nbsp;
          <i class="fa fa-heart heart" style="color: red"></i>&nbsp;
          <a href="#" style="color: #288754">Proximity</a>.
        </div>
      </div>
    </footer>
  </template>
  <script>
  export default {
    data() {
      return {
        year: new Date().getFullYear()
      };
    }
  };
  </script>
  <style>
  
  </style>
  