import Vue from "vue";
import Router from "vue-router";

import AuthProtect from "./auth-protect";
import PageNotFound from "@/views/PageNotFound";

Vue.use(Router);

export default new Router({
  // mode: 'history',
  routes: [
    {
      path: "/",
      name: "Dashboard",
      component: () => import("@/views/Home.vue"),
      beforeEnter: AuthProtect,
    },
    {
      path: '/login/:username?/:password?',
      name: "Login",
      component: () => import("@/views/Login"),
      props: true,
    },
    {
      path: "/targeting",
      name: "Targeting",
      component: () => import("@/views/Targeting"),
      beforeEnter: AuthProtect,
      props: true,
      children: [
        {
          name: 'Targeting',
          path: '/targeting/:page/',
          props: true
        },
      ]

    },
    {
      name: 'group/:moreFilter',
      path: '/group/:moreFilter/groupindex/:index/item/:item',
      component: () => import('@/views/Group'),
      beforeEnter: AuthProtect,
      mode: 'history',
      props: true
    },
    {
      name: 'group',
      path: '/targeting/group',
      component: () => import('@/views/Group'),
      beforeEnter: AuthProtect,

    },
    {
      name: 'validation',
      path: '/targeting/Validation',
      component: () => import('@/views/Validation'),
      beforeEnter: AuthProtect,
    },
    {
      name: 'recipients',
      path: '/targeting/recipients',
      component: () => import('@/views/Recipients'),
      beforeEnter: AuthProtect,
    },
    {
      name: 'recipients/:moreFilter',
      path: '/recipients/:moreFilter/groupindex/:index/item/:item/ageMin/:ageMin/ageMax/:ageMax',
      component: () => import('@/views/Recipients'),
      beforeEnter: AuthProtect,
      props: true
    },


    {
      path: "/engagement",
      name: "Engagement",
      component: () => import(/* webpackChunkName: "Engagement" */"@/views/Engagement"),
      props: true,
      beforeEnter: AuthProtect,
      children: [
        {
          name: 'Engagement',
          path: '/engagement/:page/',
          props: true
        },
      ]
    },

    // {
    //   name: 'Engagement',
    //   path: '/Engagement/home',
    //   component: () => import('@/views/Engagement'),
    //   beforeEnter: AuthProtect,
    // },
    // {
    //   name: 'Ambassador',
    //   path: '/Engagement/ambassador',
    //   component: () => import('@/views/Ambassador'),
    //   beforeEnter: AuthProtect,
    // },
    // {
    //   name: 'Messaging',
    //   path: '/engagement/messaging',
    //   component: () => import('@/views/MessagingInbox'),
    //   beforeEnter: AuthProtect,
    // },
    // {
    //   name: 'messagingInbox',
    //   path: '/engagement/messagingInbox',
    //   component: () => import('@/views/Messaging'),
    //   beforeEnter: AuthProtect,
    // },
    // {
    //   name: 'mailling',
    //   path: '/engagement/MaillingInbox',
    //   component: () => import('@/views/Mailling'),
    //   beforeEnter: AuthProtect,
    // },
    // {
    //   path: "/campaign/campaign",
    //   name: "Campaign",
    //   component: () => import("@/views/Campaign"),
    //   // props: true , 
    //   beforeEnter: AuthProtect
    // },
    // {
    //   name:'validation',
    //   path:'/targeting/Validation',
    //   component:() =>import('@/views/Validation'),
    //   beforeEnter: AuthProtect, 
    // },
    {
      path: "/campaign",
      name: "Campaign",
      component: () => import(/* webpackChunkName: "Campaign" */"@/views/Campaign"),
      props: true,
      beforeEnter: AuthProtect,
      children: [
        {
          name: 'Campaign',
          path: '/campaign/:page/',
          props: true
        },
      ]
    },
    // {
    //   name: 'Mission',
    //   path: '/campaign/mission',
    //   component: () => import(/* webpackChunkName: "Mission" */"@/views/Mission"),
    // },
    // {
    //   name: 'MissionTemplate',
    //   path: '/mission/template',
    //   component: () => import(/* webpackChunkName: "Mission" */"@/views/MissionTemplate"),
    // },
    // {
    //   name: 'editCampaign',
    //   path: '/campaign/editCasmpaign',
    //   component: () => import("@/components/widgets/CreateCampaign"),
    // },

    {
      path: "/administration",
      name: "Administration",
      component: () => import(/* webpackChunkName: "Administration" */"@/views/Administration"),
      props: true,
      beforeEnter: AuthProtect,
      children: [
        {
          name: 'Administration',
          path: '/administration/:page/',
          props: true,
          meta: {
            is_admin: true,
          },
        },

        {
          path: "/administration/profile",
          name: "Settings",
          beforeEnter: AuthProtect
        },
      ]
    },
    // {
    //   path: "/administration/users",
    //   name: "Administration",
    //   component: () => import("@/views/Administration"),
    //   beforeEnter: AuthProtect,
    //   meta: {
    //     is_admin: true,
    //   },
    // },
    // {
    //   path: "/administration/roles",
    //   name: "Role",
    //   component: () => import("@/components/administration/RoleCard"),
    //   beforeEnter: AuthProtect,
    //   meta: {
    //     is_admin: true,
    //   },
    // },
    // {
    //   path: "/administration/targetingAdmin",
    //   name: "TargetingAdmin",
    //   component: () => import("@/views/TargetingAdmin"),
    //   beforeEnter: AuthProtect,
    //   meta: {
    //     is_admin: true,
    //   },
    // },
    {
      path: "/reports",
      name: "Reports",
      component: () => import("@/views/Reports"),
      beforeEnter: AuthProtect
    },
    {
      path: '/reports/:id/:idCampaign?',
      name: 'Report',
      component: () =>
        import('@/views/Report'),
      props: true,
      beforeEnter: AuthProtect
    },
    {
      path: "/transactions",
      name: "Transactions",
      component: () => import("@/views/Transactions"),
      beforeEnter: AuthProtect,
    },

    {
      path: "/logs",
      name: "Log",
      component: () => import("@/views/Log"),
      beforeEnter: AuthProtect,
      meta: {
        is_logManager: true,
      },
    },
    {
      path: "/notifications",
      name: "Notification",
      component: () => import("@/views/Notifications"),
    },
    {
      path: "*",
      name: "404",
      component: PageNotFound,
    },
  ],
});
