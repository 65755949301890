<template>
  <div class="container">
    <h1 class="text-center">Ouuppsss! Page not found</h1>
  </div>
</template>
<script>
export default {
  name: "PageNotFound",
};
</script>
