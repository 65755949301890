<template>
  <!-- <div
    class="sidebar"
    :style="sidebarStyle"
    :data-color="backgroundColor"
    :data-image="backgroundImage"
  > -->
  <div class="sidebar" :data-color="backgroundColor">
    <div class="sidebar-wrapper">
      <div class="logo">
        <a href="#" class="simple-text logo__container">
          <div class="logo-img">
            <img src="../../assets/img/ProximityCut_.png" alt="Proximity" />
          </div>
        </a>
      </div>
      <!-- <slot name="content"></slot> -->
      <ul class="nav nav-main__links">
        <slot>
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            @click="closeNavbar"
            :link="link"
          >
            <i :class="link.icon"></i>
            <p>{{ link.name }}</p>
          </sidebar-link>
        </slot>
      </ul>
      <ul class="nav nav-bottom" v-if="$slots['bottom-links']">
        <slot name="bottom-links"></slot>
      </ul>
    </div>
  </div>
</template>
  <script>
import SidebarLink from "./SidebarLink.vue";

export default {
  components: {
    SidebarLink,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "gray",
      validator: (value) => {
        let acceptedValues = [
          "",
          "white",
          "blue",
          "azure",
          "green",
          "orange",
          "red",
          "purple",
          "gray",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    activeColor: {
      type: String,
      default: "success",
      validator: (value) => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
};
</script>
  <style>
.sidebar:not(.padded) .sidebar-wrapper {
  display: flex;
  flex-direction: column;
}
.sidebar:not(.padded) .nav-main__links {
  flex: 1;
}
.sidebar:not(.padded) .sidebar-wrapper .logo .logo__container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
</style>
  