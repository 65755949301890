<template>
  <navBar :routes="routes" />
</template>

<script>
import navBar from "./customNavbar";
export default {
  name: "Campaign-nav",
  components: { navBar },
  computed: {
    routes() {
      return [
        {
          route: "/campaign",
          name: this.$t("Navigation.Home"),
        },
        {
          route: "/campaign/Campaign",
          name: this.$t("Navigation.Campaigns"),
        },
        {
          route: "/campaign/Mission",
          name: this.$t("Navigation.Mission"),
        },
        {
          route: "/campaign/MissionTemplate",
          name: this.$t("Navigation.Template"),
        },
        {
          route: "/campaign/Survey",
          name: this.$t("Navigation.SurveyTemplate"),
        },
      ];
    },
  },
};
</script>