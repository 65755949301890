<template>
  <div class="wrapper">
    <side-bar style="background-color: #f5365c">
      <sidebar-link to="/" exact>
        <i class="fa fa-home" style="color: #5e72e4"></i>
        <p class="p-text">{{ $t("Navigation.Home") }}</p>
      </sidebar-link>
      <sidebar-link to="/targeting" :exact="false">
        <i class="fa fa-bullseye" style="color: #5e72e4"></i>
        <p class="p-text">{{ $t("Navigation.Targeting") }}</p>
      </sidebar-link>
      <sidebar-link to="/campaign" :exact="false">
        <i class="fa fa-bullhorn" style="color: #fb6340"></i>
        <p class="p-text">{{ $t("Navigation.Campaigns") }}</p>
      </sidebar-link>
      <sidebar-link to="/engagement" :exact="false">
        <i class="fa fa-comments" style="color: #ffd600"></i>
        <p class="p-text">{{ $t("Navigation.Engagement") }}</p>
      </sidebar-link>
      <sidebar-link to="/reports">
        <i class="fa fa-list-alt" style="color: #f5365c"></i>
        <p class="p-text">{{ $t("Navigation.Reports") }}</p>
      </sidebar-link>
      <hr
        class="my-3"
        style="border-top: 1px solid rgb(177, 177, 177); width: 200px"
      />
      <sidebar-link to="/administration" :exact="false">
        <i class="fa fa-users" style="color: #4c4c4c"></i>
        <p class="p-text">{{ $t("Navigation.Administration") }}</p>
      </sidebar-link>
      <sidebar-link to="/notifications">
        <i class="fa fa-bell" style="color: #4c4c4c"></i>
        <p class="p-text">{{ $t("Navbar.Notification") }}</p>
      </sidebar-link>
      <li>
        <b-button @click="logout" class="nav-link" variant="no-border"
          ><i class="fa fa-sign-out-alt" style="color: #4c4c4c"></i>
          <p class="p-text">{{ $t("Navbar.Logout") }}</p></b-button
        >
      </li>

      <!-- <sidebar-link> </sidebar-link> -->
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
  <style lang="scss">
</style>
  <script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
  },
  methods: {
    logout() {
      this.$store.dispatch("Auth/logout", { token: this.user.token });
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>
  
  <style lang="scss">
.p-text {
  color: #000;
}
</style>