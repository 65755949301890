import axios from 'axios' 

export const request = function (baseBridge) {
    return axios.create({
        baseURL: baseBridge,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    },
    )
}

export const mediaRequest = function (baseBridge) {
    return axios.create({
        baseURL: baseBridge,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}
 